<template>
    <div class="qr-code-login">
        <div
            v-if="templateInfo.loginMethod.find(e => ['1', '4'].includes(e))"
            class="account-btn"
            @click="handleLoginWithAccount"
        >使用账号登录</div>
        <div class="login-header">
            <div class="login-title login-title_active">扫码登录</div>
        </div>
        <div class="login-qrcode">
            <div
                v-if="templateInfo.loginMethod.includes('2')"
                class="login-qrcode-btn"
                @click="handleOtherLoginClick('WeChat')">
                <img :src="imgWeChat" alt="weChat">
                <span>微信扫一扫</span>
            </div>
            <div
                v-if="templateInfo.loginMethod.includes('3')"
                class="login-qrcode-btn"
                @click="handleOtherLoginClick('DingTalk')">
                <img :src="imgDingTalk" alt="dingTalk">
                <span>钉钉扫一扫</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QRCodeLogin',
    components: {},
    props: {
        templateInfo: {
            type: Object
        },
        loginForm: {
            type: Object
        }
    },
    data() {
        return {}
    },
    computed: {
        imgWeChat() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_wechat.png')
        },
        imgDingTalk() {
            return require('@/assets/images/loginPageTemplate/theme1/login_box_qrcode_dingtalk.png')
        }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        handleLoginWithAccount() {
            this.$eventDispatch('loginMethodSwitch', this.templateInfo.loginMethod.includes('1') ? '1' : '4');
        },
        handleOtherLoginClick(type) {
            this.$eventDispatch('otherLoginClick', type);
        }
    }
}
</script>

<style lang="scss" scoped>
$coefficient: var(--coefficient, 1px);

.qr-code-login {
    position: relative;
    width: 100%;
    height: 100%;

    .account-btn {
        position: absolute;
        top: calc(59 * #{$coefficient});
        right: calc(47 * #{$coefficient});
        font-size: calc(16 * #{$coefficient});
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
        cursor: pointer;
    }
}

.login-header {
    padding-top: calc(43 * #{$coefficient});
    display: flex;
    align-items: flex-end;
    justify-content: center;

    .login-title {
        font-size: calc(22 * #{$coefficient});
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        cursor: pointer;

        +.login-title {
            margin-left: calc(36 * #{$coefficient});
        }
    }
    .login-title_active {
        font-size: calc(28 * #{$coefficient});
        color: #05042A;
    }
}

.login-qrcode {
    margin-top: calc(109 * #{$coefficient});
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .login-qrcode-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        +.login-qrcode-btn {
            margin-left: calc(110 * #{$coefficient});
        }

        img {
            width: calc(86 * #{$coefficient});
            height: calc(86 * #{$coefficient});
            object-fit: contain;
            display: inline-block;
        }

        span {
            font-size: calc(16 * #{$coefficient});
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #05042A;
            margin-top: calc(17 * #{$coefficient});
        }
    }
}
</style>
